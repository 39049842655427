import { PageViewLayer } from '@component/dataLayer'
import { pushDataLayer } from '../index'

export default function PageViewDataLayer() {

  return (
    <PageViewLayer
      data={{
        'vertical': 'form',
        'vertical_type': 'form',
        'event': 'page_view',
        'page_type': 'detail'
      }}
    />
  )
}

export const formDataLayer = {
  submitClick(leadGen = false) {
    pushDataLayer({
      'vertical': 'form',
      'vertical_type': leadGen ? 'lead_gen_form' : 'form',
      'page_type': 'detail'
    })

    if (leadGen) {
      pushDataLayer({
        'event': 'email_agent_success',
      })
    }
  }
}